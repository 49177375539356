<template>
  <div class="witchdrawalRecords-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    <el-dialog title="提现" top="30vh" width="380px" :visible.sync="withdrawalDialogVisible"  :close-on-click-modal="false">
      <div class="withdrawalText">
        <div class="withdrawalText-title">您确认要提现吗？</div>
        <div class="withdrawalText-tip">法定节假日(非工作日)不允许提现</div>
      </div>
      <div class="withdrawalBtn">
        <el-button @click="withdrawalDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="withdrawalSure()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          title: "提现记录",
          search: [{
              key:"roleType",
              label: "角色类型",
              defaultValue: "1",
              type: "select",
              opt: {
                list: [{
                        label: "商家",
                        value: "1",
                      },
                      {
                        label: "供应商",
                        value: "2"
                      },
                      {
                        label: "经销商",
                        value: "3"
                      }],
              }
            },
            {
              key: "orderNo",
              label: "提现单号",
            },
            {
              key: "bankNo",
              label: "银行账号",
            },
            {
              key: "openBank",
              label: "银行户名",
            }, {
              key:"state",
              label: "提现状态",
              type: "select",
              opt: {
                list: [{
                        label: "待审核",
                        value: "0",
                      },
                      {
                        label: "提现中",
                        value: "1"
                      },{
                        label: "提现成功",
                        value: "2",
                      },{
                        label: "提现中，存在网络延迟",
                        value: "3",
                      },
                      {
                        label: "提现失败",
                        value: "4"
                      }],
              }
            },{
              key: "startTime",
              label: "提现时间",
              type: "data-range"
            },
            {
              key: "realAmount",
              label: "到账时间",
              type: "data-range"
            }
          ],
          columns: [{
              label: "提现单号",
              key: "orderNo",
              align: "center"
            },
            {
              label: "角色",
              key: "roleType",
              align: "center"
            },
            {
              label: "名称",
              key: "merchantName",
              align: "center"
            },
            {
              label: "银行账号",
              key: "bankNo",
              align: "center"
            },
            {
              label: "银行户名",
              key: "accountName",
              align: "center"
            },
            {
              label: "银行名称",
              key: "openBank",
              align: "center"
            },
            {
              label: "提现金额",
              key: "amount",
              align: "center"
            },
            {
              label: "到账金额",
              key: "realAmount",
              align: "center"
            },
            {
              label: "提现时间",
              key: "startTime",
              align: "center"
            },
            {
              label: "到账时间",
              key: "realTime",
              align: "center"
            },
            {
              label: "手续费",
              key: "fee",
              align: "center"
            },
            {
              label: "状态",
              key: "stateStr",
              align: "center"
            },
            {
              label: "操作",
              key: "actionStr",
              type: "actionStatus",
              opt: {
                ativeStatus: "提现",
                on(row, item) {
                  _this.rowItem = row;
                  _this.withdrawalDialogVisible = true;
                }
              }
            }
          ],
          exportButtons: [{
            type: "Export",
            url: this.EXport + "/mall-service/allinpay/v1/withdraw/export",
            listName: '提现记录'
          }],
        },
        merchantFlag: false,
        rowItem: {},
        withdrawalDialogVisible: false,
        // userInfo: {},
      };
    },
    created() {
      console.log("witchdrawalRecords-list created!!"); 
    },
    activated() {
      // this.userInfo = this.cache.getLS('userInfo');
      console.log("witchdrawalRecords-list activated!!");
      this.merchantFlag = this.getLoginType('loginType');
      this.supplierFlag = this.isSupplierLogin('loginType');
      let isDealerLogin = this.isDealerLogin();
      if (this.merchantFlag || this.supplierFlag || isDealerLogin) {
        this.opt.search.forEach((item, index) => {
          if (item.key == 'roleType') {
            this.opt.search.splice(index, 1);
          }
          if (item.key == 'state') {
            this.opt.search.splice(index, 1);
          }
        })
        this.opt.columns.forEach((item, index) => {
          if (item.key == 'merchantName') {
            this.opt.columns.splice(index, 1);
          }
        })
        this.opt.columns.forEach((item, index) => {
          if (item.key == 'roleType') {
            this.opt.columns.splice(index, 1);
          }
        })
        this.opt.columns.forEach((item, index) => {
          if (item.key == 'actionStr') {
            this.opt.columns.splice(index, 1);
          }
        })
      }
    },
    deactivated() {
      this.withdrawalDialogVisible = false;
    },
    methods: {
      onGet(opt) {
        // 平台登录
        // if(this.userInfo.roleType == 1) {
        //   this.opt.columns.forEach(item => {
        //     if(item.key == 'merchantName') {
        //       if(opt.searchForm.roleType == 2) {
        //         item.label = "供应商名称"
        //       }else {
        //         item.label = "商家名称"
        //       }
              
        //     }
        //   })
        // }
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm,
          withdrawStartTime: opt.searchForm['startTime'] ? this.format(opt.searchForm['startTime'][0]) : null,
          withdrawEndTime: opt.searchForm['startTime'] ? this.format(opt.searchForm['startTime'][1]) : null,
          realStartTime: opt.searchForm['realAmount'] ? this.format(opt.searchForm['realAmount'][0]) : null,
          realEndTime: opt.searchForm['realAmount'] ? this.format(opt.searchForm['realAmount'][1]) : null,
        };
        if (dto.startTime) {
          delete dto.startTime
        }
        if (dto.realAmount) {
          delete dto.realAmount
        }
        this.post("mall-service/allinpay/v1/withdraw/page", dto, {
          isUseResponse: true
        }).then(res => {
          if (res.data.code == 0) {
            // 商家：0 1 3 4审核中  2审核通过
            // 平台： 0待审核 1提现中 2提现成功 3提现中，存在网络延迟 4 提现失败  （除2外都显示 提现审核按钮）
            if (res.data.data.data.length > 0) {
              if (this.merchantFlag) { //商家
                res.data.data.data.forEach(item => {
                  if (item.state == 2) {
                    item.stateStr = "审核成功";
                  } else {
                    item.stateStr = "审核中";
                  }
                  item.bankNo = item.bankNo ? item.bankNo.slice(0, 4) + '******' + item.bankNo.slice(item.bankNo
                    .length - 4, item.bankNo.length) : '--';
                });
              } else {
                res.data.data.data.forEach(item => {
                  item.stateStr = ["待审核", "提现中", "提现成功", "提现中，存在网络延迟", "提现失败"][item.state];
                  item.bankNo = item.bankNo ? item.bankNo.slice(0, 4) + '******' + item.bankNo.slice(item.bankNo
                    .length - 4, item.bankNo.length) : '--';
                  item.roleType = ["商家","供应商","经销商"][item.roleType-1]
                  if (item.state == 0 || item.state == 4) {
                    item.actionStr = '提现'; //可点击
                  } else {
                    item.actionStr = ' 提现'; //不可点击
                  }
                });
              }
            }
            opt.cb(res.data.data);
          }
        });
      },
      // 确认提现
      withdrawalSure() {
        let dto = {
          id: this.rowItem.id,
          bizUserId: this.rowItem.memberUserId,
        };
        this.post('/mall-service/allinpay/v1/withdraw/apply', dto, {
          isUseResponse: true
        }).then(res => {
          if (res.data.code == 0) {
            this.withdrawalDialogVisible = false;
            // 刷新列表
            this.$refs.myList.onRefresh();
            this.$message({
              message: '提交成功',
              type: 'success',
            })
          }
        })
      },
    }
  };
</script>
<style lang="scss" scoped>
  .withdrawalText {
    text-align: center;
  }

  .withdrawalText-title {
    font-size: 17px;
  }

  .withdrawalText-tip {
    color: red;
    padding-bottom: 29px;
    padding-top: 6px;
    font-size: 12px;
  }

  .withdrawalBtn {
    text-align: center;
  }
</style>